<template>
    <section>
        <div>
            {{ this.billData.message }}
        </div>

    </section>
</template>
     
<script>

export default {
    name: "hydropower.vue",
    // 合计uuid传参
    props: {
        billData: {
            type: Object,
            default: () => ({})
        }
    },

    data() {
        return {
            detailsDialogVisible: false,
            uuid: null

        };
    },
    async created() {
        // 页面初始化
        // 获取用户uuid
        const { uuid } = this.billData;
        this.uuid = uuid;
    },
    methods: {

    }
}
</script>
     
<style scoped></style>